input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.input-group-credits{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    width: 240px;
}

.edit-credits{
    position: absolute;
    right: 5%;
    top: 20%;
}

.card--adminUser{
    max-height: none;
    max-width: 690px;
}

.btn-primary--save{
    margin: 20px 10px;
    width: 242px;
}

.select{
    width: 240px;
    height: 36px;
    border-radius: 18px;
    padding: 0 10px;
    text-align: center;
    border: 2px solid #aeb1b1;
    box-sizing: border-box;
}

.userData{
    margin-bottom: 20px;
}

.cards-container{
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.card--aside{
    margin-right: 80px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-admin{
    background: linear-gradient(90deg, rgba(0, 180, 228, 0.16) -7.9%, rgba(242, 213, 0, 0.35) 82.48%);
    width: 100%;
    height: 340px;
    margin-bottom: 80px;
    text-align: center;
}

.adminUser-title{
    padding: 0;
}

.main-title{
    margin: 10px 10px 40px 10px;
}

.card--users{
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: solid 1.5px #04a6f3;
}

.aside-image{
    width: 50px;
}

.aside-image-selected{
    width: 150px;
}

.profile-circle {
    width: 100px;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
}

.user-img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
}

.user-img-selected{
    width: 135px;
    height: 135px;
    border-radius: 50%;
    position: absolute;
}


aside p{
    font-size: 13px;
    margin: 0;
}

aside a{
    font-size: 14px;
    text-decoration: none;
}

.edit-users{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}

.edit{
    color: var(--secondary-color);
    font-weight: bold;
    cursor: pointer;
}

.standby{
    color: var(--delete-color);
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
}

.filter-width{
    padding: 0 20px;
    width: 100%;
    margin-bottom: 30px;
}

.filter{
    width: 100%;
    height: 36px;
    border-radius: 18px;
    padding: 0 10px;
    text-align: center;
    border: 2px solid #aeb1b1;
    box-sizing: border-box;
}

.edit-users img{
    margin-left: 5px;
    cursor: pointer;
}

.cards-box{
    padding: 0 20px;
    max-height: 850px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.disable{
    border: solid 3px #eb6872;
}

.btn-delete--disable{
    width: 242px;
    height: 38px;
}

.disable-message{
    color: var(--delete-color);
    display: none;
    margin: 15px 15px;
}

.disable-buttons{
    display: none;
}

.show{
    display: flex;
  }

.hide{
    display: none;
}

.default-message{
    margin: 30px;
}

.card--aside label{
    margin-bottom: 5px;
}

/* responsive */

@media (max-width:1220px){
    .card--aside {
        margin-right: 20px;
    }
}

@media (max-width:1220px){
    .card--aside {
        margin-right: 0px;
    }

    .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .card--adminUser {
        max-width: 80%;
        max-height: initial;
    }

    .card--aside {
        max-width: 72%;
        max-height: initial;
    }

    .card--users div{
        max-width: fit-content;
    }
}

@media (max-width:730px){
    .edit-users a{
        display: none;
    }

    .card--users {
        margin-bottom: 10px;
        padding: 5px 5px;
    }

    .main-admin img{
        width: 80px;
    }

    .edit{
        display: none;
    }

    .standby{
        display: none;
    }

    .users-info {
        max-width: 110px!important;
        padding: 0;
    }

    .userData div {
        max-width: 95%;
    }
}

@media (max-width:450px){
    .users-info h6{
        font-size: 13px;
    }

    .users-info p{
        font-size: 11px;
    }

    .profile-pic{
        padding: 0;
    }
}