.main{
    padding: 40px 0px;
}

.main h6{
    padding-top: 20px;
}

.main p{
    padding: 20px 0 0 0;
}

.main-content{
    width: 50%;
}

.h-auto{
    height: auto;
}

.video-container{
    height: 550px;
    padding-bottom: 80px;
    max-width: 850px;
}

@media (max-width:996px){
    .video-container{
        height: 380px;
        padding-bottom: 50px;
    }

    .main-content{
        width: 100%;
    }
    
}

@media (max-width:730px){
    .video-container{
        height: 280px;
    }
}

@media (min-width:800px){
    .video-container{
        width: 55%;
    }
}