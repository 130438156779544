.search-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Ajusta el margen derecho para separar la imagen del texto */
}

.qr-container{
  position: relative;
}

.qr-border{
  width: 70%;
  height: 70%;
}

.qr-img{
  position: absolute;
}

.title {
  font-weight: bold;
}

.vector-icon {
  width: 90px;
  height: 100px;
}

.success-icon {
  width: 110px;
  height: 110px;
}

.time-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.subtitle {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.select-container {
  margin: 5px;
}

.rounded-select {
  border-radius: 20px;
  border-color: darkgrey;
  padding: 10px;
  font-size: 16px;
  width: 320px;
}

.select-hour-container {
  display: flex;
  justify-content: space-between;
}

.time-select-wrapper {
  margin-left: 5px; /* Ajusta el margen entre los contenedores según tus necesidades */
}

.rounded-select-hour {
  border-radius: 20px;
  border-color: darkgrey;
  padding: 10px;
  font-size: 16px;
  width: 155px;
}

.label {
  font-size: 24px;
  font-weight: bold;
}

.time-container {
  display: flex;
  margin-top: 70px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.calendar-image{
  margin: 5px 0;
}

.card--booking{
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 80%;
  padding: 20px 20px;
  min-height: 170px;
}

.text-container{
  text-align: center;
  padding: 0 20px;
}

.btn-delete-booking{
  width: 242px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px!important;
}

.qr-modal .modal-content{
  text-align: center;
  padding: 30px;
}

.qr-modal .modal-body{
  padding: 55px 0;
}

.buttons-row{
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-qr{
  color: var(--secondary-color);
  cursor: pointer;
  margin-top: 10px;
  font-weight: bolder;
}

.show-qr:hover{
  color: #44b1d5;
}

.room-images{
  border-radius: 30px;
  margin-bottom: 40px;
  width: 460px;
  height: auto;
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.25);
  border: solid 1px lightblue;
}

.terms-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 40px 10px 40px;
  cursor: pointer;
  text-align: center;
}

.terms{
  font-weight: bold;
  cursor: pointer;
  margin: 0px;
  color: var(--secondary-color);
}

.terms img{
  margin-right: 5px;
}

.terms:hover{
  color: #33bdff;
}

.terms-acept{
  color: var(--primary-color);
}

.terms-acept:hover{
  color: var(--primary-color);
}

.modal-title {
  font-size: 26px;
}

/* responsive */

@media (max-width:996px){
  .booking-data{
    margin: 15px 0;
  }

  .rounded-select {
      padding: 5px;
      font-size: 14px;
  }

  .subtitle {
    font-size: 14px;
    margin-top: 5px;
  }

  .rounded-select-hour {
    padding: 5px;
    font-size: 14px;
  }

  .room-images{
    width: 360px;
  }
}

@media (max-width:730px){
  .room-images{
    width: 260px;
  }

  .terms{
    font-size: 16px;
  }
}