.scanner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.scanner-box{
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid #00bfff;
    padding: 20px 0;
    border-radius: 10px;
    text-align: center;
    margin: 50px 0;
    box-shadow: 1px 2px 10px #00000057;
}

.scanner{
    width: 80%;
    border: 1px solid white!important;
    padding: 10px 0 30px 0!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scanner button{
    margin: 10px;
    border-radius: 50px;
    font-weight: bold;
    height: 38px;
    text-align: center;
    padding: 0;
}

.scanner img{
    width: 85px;
    margin-bottom: 20px;
}

.scanner video{
    width: 100%!important;
}
 
#html5-qrcode-anchor-scan-type-change {font-size:0}
#html5-qrcode-button-camera-permission {font-size:0}
#html5-qrcode-button-camera-permission::after {
content:"Activar cámara";
font-size:initial;
}
#html5-qrcode-button-camera-start {font-size:0}
#html5-qrcode-button-camera-start::after {
content:"Iniciar Lector";
font-size:initial;
}
#html5-qrcode-button-camera-stop {font-size:0}
#html5-qrcode-button-camera-stop::after {
content:"Cancelar";
font-size:initial;
}

div#reader__scan_region {
    width: 80%!important;
}

/* responsive */

@media (max-width:996px){
    .scanner-box{
        width: 400px;
    }
}

@media (max-width:730px){
    .scanner-box{
        width: 330px;
    }
}

@media (max-width:350px){
    .scanner-box{
        width: 290px;
    }
}

@media (max-width:300px){
    .scanner-box{
        width: 220px;
    }
}