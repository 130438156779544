.photos img {
  width: 110%;
  border-radius: 8px;
}

.photoContainer {
  height: 400px;
  width: 110%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.span {
  width: fit-content;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  font-size: 14px;
}

@media (max-width:996px){
  .photos img {
    width: 100%;
  }
  .photoContainer {
    width: 100%;
    padding: 10px;
  }

  .photoContainer h3{
    font-size: 18px!important;
    margin-top: 5px;
  }
}