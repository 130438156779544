/* variables */
:root {
    --primary-color: #415674;
    --secondary-color: #04a6f3;
    --tertiary-color: #66CFA9;
    --delete-color: #EB6872;
    --gradient-color: linear-gradient(90deg, rgba(0, 180, 228, 0.16) -7.9%, rgba(242, 213, 0, 0.35) 82.48%);
}

.App{
    padding: 0px 80px;
}

p, h2, h6, b, h1, h3, h4, h5 {
    color: var(--primary-color);
}

.bullet{
    color: var(--tertiary-color);
}

.secondary-color{
    color: var(--secondary-color);
}

h2{
    font-size: 36px;
    line-height: 1.3334em;
    padding-top: 20px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
    margin-bottom: 40px;
    margin-top: 20px;
}

.header img{
    height: 80px;
    width: auto;
}

.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 30px 0;
    bottom: 0;
    width: 100%;
}

.footer img {
    width: 200px;
    height: auto;
}

.btn-primary {
    background-color: #04a6f3; 
    color: #ffffff; 
    border: none; 
    border-radius: 20px;
    cursor: pointer; 
    font-size: 14px;
    font-weight: bolder;
    text-decoration: none;
    min-width: 110px;
    text-align: center;
    height: 38px;
    font-size: 14px;
    box-shadow: 1px 2px 5px #00000057;
    width: 232px;
    padding: 0;
    margin: 10px;
}

.btn-primary:hover {
    background-color: #0a8ba5; 
}

.btn-primary:disabled,
.btn-primary[disabled]{
    background-color: #888888;
    color: #c5cfdb;
    cursor: initial;
}

.btn-secondary{
    padding: 0;
    width: 232px;
    height: 38px;
    border-radius: 20px;
    border: 2px solid #04a6f3;
    cursor: pointer;
    font-weight: bold;
    margin-top: 20px;
    background-color: white;
    min-width: 110px;
    text-align: center;
    color: var(--primary-color);
    box-shadow: 1px 2px 5px #00000057;
    font-size: 14px;
    margin: 10px;
}

.btn-secondary:hover{
    background-color: rgb(230, 224, 224);
}

.btn-delete {
    background-color: #EB6872; 
    color: #ffffff; 
    border: none; 
    border-radius: 20px;
    cursor: pointer; 
    font-size: 14px;
    font-weight: bolder;
    text-decoration: none;
    min-width: 110px;
    text-align: center;
    margin-top: 10px;
    height: 38px;
    box-shadow: 1px 2px 5px #00000057;
    width: 232px;
    margin: 10px;
    padding: 0;
}

.btn-delete:hover {
    background-color: #eb4856;
}

.btn-primary-header{
    width: 130px;
}

.btn-small{
    width: 110px;
    height: 34px;
}

.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

card{
    padding: 30px 40px;
    border: solid 3px #04a6f3;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    min-width: 120vh;
    margin: 30px 0;
}

span{
    color: var(--secondary-color);
    font-weight: bolder;
}

.delete{
    color: var(--delete-color);
    font-weight: bold;
    margin: 20px 0 30px 0;
}

.main-gradient{
    background: linear-gradient(90deg, rgba(0, 180, 228, 0.16) -7.9%, rgba(242, 213, 0, 0.35) 82.48%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    width: 100%;
    margin: 0 0 40px 0;
    text-align: center;
}

.margin-10{
    margin: 10px;
}

.margin-20{
    margin: 20px;
}

.margin-50{
    margin: 50px;
}

.mb-20{
    margin-bottom: 20px;
}

.mt-20{
    margin-top: 20px;
}

.pass-error-message{
    color: var(--delete-color);
    margin: 10px;
}

.center-row{
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-gradient img{
    width: 90px;
}

/* responsive */

@media (max-width:996px){
    h2{
        font-size: 28px;
    }

    .App{
        padding: 0px 30px;
    }
}

@media (max-width:730px){
    h2{
        font-size: 24px;
    }

    .header img{
        height: 70px;
    }

    .header{
        padding: 5px;
        margin-bottom: 20px;
    }

    .main-gradient{
        padding: 15px;
        margin: 0 0 30px 0;
    }

    .footer{
        padding: 30px 0 30px 0;
    }

    .btn-primary{
        width: 180px;
    }

    .btn-secondary{
        width: 180px;
    }

    .btn-delete{
        width: 180px;
    }
}