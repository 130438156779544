.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
}

.title {
  margin: 20px 10px;
  text-align: center;
}

.card {
  width: 90vw;
  max-width: 460px;
  height: auto;
  max-height: 600px;
  border: 4px solid #00bfff;
  padding: 20px 0;
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 1px 2px 10px #00000057;
}

.card h1 {
  margin-bottom: 20px;
}

.input-group {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 10px 0;
}

.input-group label {
  margin: 5% 0;
}

.input-group input {
  width: 240px;
  height: 36px;
  border-radius: 18px;
  padding: 0 10px;
  text-align: center;
  margin-bottom: 10px;
  border: 2px solid #aeb1b1;
  box-sizing: border-box;
}
.button-link {
  display: inline-block;
  padding: 0;
  width: 242px;
  height: 36px;
  border-radius: 18px;
  background-color: #00b7dd;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  line-height: 36px; 
  transition: background-color 0.3s ease; 
}

.button-link:hover {
  background-color: #0099b0; 
}

.login-button {
  width: 242px;
  height: 36px;
  border-radius: 18px;
  background-color: #00b7dd;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bottom-image {
  bottom: 20px;
  width: 100%;
}

.back-button:hover {
  background-color: #afb2b3;
}

.Message{
  color: var(--secondary-color);
  font-size: 12px;
}

.m0{
  margin: 0;
}

.error-message{
  color: var(--delete-color);
  font-size: 12px;
  margin-bottom: 20px;
}

.input-container{
  width: 360px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btn-box{
  margin: 40px 0 100px 0;
}

.login-title{
  padding: 0 0 20px 0;
  text-align: center;
  font-weight: bold;
}

@media (max-width:440px){
  .card{
    max-width: 310px;
  }

  .title{
    font-size: 22px;
  }
}