hr{
    width: 95%;
    color: var(--primary-color);
}

.card--rooms{
    margin: 50px 0;
}

.card--rooms__title{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    text-align: center;
    padding: 0 30px;
}

.card--rooms__title img{
    margin-right: 15px;
}

.card--rooms__items{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    align-items: start;
    padding: 0 30px;
}

.room-buttons{
    margin: 30px 0 0 0;
}

.room-buttons button{
    margin: 0px 5px;
}

.add-room{
    width: 242px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* responsive */

@media (max-width:996px){
    .card--rooms {
        margin: 20px 0;
    }

    .rounded-select {
        padding: 5px;
        font-size: 14px;
    }
    
    .subtitle {
        font-size: 16px;
        margin-top: 5px;
    }

    .rounded-select-hour {
        padding: 5px;
        font-size: 14px;
    }
}