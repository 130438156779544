.clips-container{
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
}

.clips-container span{
    font-weight: bold;
    color: #00b7dd;
}

.clip1{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    height: 320px;
    margin-bottom: 20px;
    max-width: 700px;
}

.clip2{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    height: 320px;
    margin-bottom: 20px;
    max-width: 700px;
}

@media (max-width:730px){
    .clip1{
        height: 280px;
    }
    .clip2{
        height: 280px;
    }
}

@media (min-width:1500px){
    .clip1{
        width: 40%;
    }
    .clip2{
        width: 40%;
    }
}