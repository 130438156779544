.faqContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider {
    border-top: 1px solid gray
}

svg.svg-inline--fa.fa-arrow-down {
  font-size: 1rem;
  color: #00b7dd!important;
}

svg.svg-inline--fa.fa-arrow-up {
  font-size: 1rem;
  color: #e31c79!important;
}

.faqContainer p{
  font-weight: bold;
}

.foundation{
  padding-bottom: 20px;
}