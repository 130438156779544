.title{
    text-align: center;
    padding: 0;
}

.edit-room-container {
    width: 40%;
    margin: 0 auto;
    padding: 20px;
    border: 4px solid #04a6f3;
    border-radius: 8px;
    background-color: #fff;
  }

  .create-room-container {
    text-align: center;
    width: 40%;
    margin: 0 auto;
    padding: 20px;
    border: 4px solid #04a6f3;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .edit-room-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px; 
  }
  
  input[type='checkbox'] {
    margin-left: 10px; /* Ajusta el valor según sea necesario */
  }
  
  label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  input[type='text'],
  input[type='number'],
  input[type='password'] {
    /* width: 100%; */
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 20px;
    height: 38px;
  }

  .group-buttons{
    display: flex;
    justify-content: space-around;
  }
  
  button {
    width: 150px;
    padding: 10px;
    background-color: #04a6f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    align-self: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .btn-delete{
    margin: 0;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  