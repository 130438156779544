/* BOTON VOLVER ARRIBA */
.buttonUp{
    width: 50px;
    height: 50px;
    background-color: lightblue;
    border-radius: 50%;
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 85px;
    right: 25px;
    border: 1px solid transparent;
    transition: all 300ms ease;
    transform: scale(0);
    box-shadow: 2px 2px 3px black;
}

.buttonUp:hover{
    background-color: lightblue;
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
}

.svg-inline--fa{
    font-size: 1.5em;
    color: black;
}

/* BOTON CONTACTO */

.buttonContact{
    width: 50px;
    height: 50px;
    background-color: #1e9d12;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 25px;
    right: 25px;
    border: 1px solid transparent;
    transition: all 300ms ease;
    box-shadow: 2px 2px 3px black;
    transform: scale(0);
}

.buttonContact:hover{
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
}

svg.svg-inline--fa.fa-phone {
    font-size: 1.1rem;
    color: beige;
}

.show{
    transform: scale(1);
}

/* FIN BOTONES */

.foot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}

  .foot img{
    height: 100px;
    width: auto;
}