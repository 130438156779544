.full-width-background {
  background: linear-gradient(
    89.61deg,
    rgba(0, 180, 228, 0.16) -7.9%,
    rgba(242, 213, 0, 0.35) 82.48%
  );
  width: 100%;
  height: 340px;
  text-align: center;
  margin-bottom: 50px;
}

.react-datepicker-wrapper {
  width: 320px;
}

.react-datepicker__input-container input {
  height: 35px;
  width: 100%;
  border-radius: 100px;
  text-align: center;
}

.cards-adminBookings-box {
    width: 70%;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.cards-adminBookings-box p {
  text-align: center;
  font-size: 24px;
}

.cards-adminBookings-box p.booking-room-name {
  color: #00b7dd;
}