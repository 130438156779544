.ideas{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.ideasContainer{
    padding: 20px 0 60px 0;
}

.ideasContainer p{
    margin: 0;
}

.iconContainer{
    background-color: lightblue;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    max-width: 50px;
    min-width: 50px;
}

.card-description{
    width: 100%;
}

.iconContainer img{
    width: 25px;
    height: 25px;
}