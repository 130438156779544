.profile-circle {
  width: 155px; 
  height: 155px; 
  margin: 20px auto; 
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.ellipse-image {
  width: 100%; 
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.aside-image{
  width: 1050px;
}

.user-img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
}

.profile-image {
  width: 100%; 
  height: 100%;
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
}

.sheet-icon {
  margin-bottom: 10px;
  width: auto;
  height: 60px;
}

.booking-button:hover {
  background-color: #007acc; 
}

.changeKey{
  text-decoration: none;
  color: var(--secondary-color);
  font-weight: bolder;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

.noReserve{
  display: none;
  color: var(--delete-color);
  font-weight: 600;
  margin: 0;
}

.admin-title{
  font-weight: bold;
  font-size: 24px;
  color: #415674;
}

.show{
  display: initial;
}

.admins-buttons{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 40px 0;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30px;
}

.btn-admin{
  height: 180px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid #04a6f3!important;
  padding: 10px!important;
}

.reserve-button{
  width: auto!important;
  border-radius: 8px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 25px!important;
  margin-bottom: 30px!important;
}

.reserve-button img{
  width: 20px;
  height: auto;
  margin-right: 10px!important;
  margin-bottom: 0!important;
}

.users-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 10px;
}

  /* responsive */

@media (max-width:850px){
  .qr-btn{
    display: flex;
  }
}

@media (max-width:466px){
  .btn-admin img{
    width: 40px;
    height: auto;
  }

  .btn-admin{
    width: 150px!important;
    height: 140px!important;
  }

  .admins-buttons{
    margin: 20px 0;
  }
}